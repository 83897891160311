<template>
  <div>
    <v-select
      v-model="item"
      :items="accounts"
      item-text="name"
      item-value="id"
      dense
      solo
      @change="change_account"
    />
  </div>
</template>

<script>
  export default {
    name: 'AccountSelect',
    data () {
      return {
        item: this.$store.getters.user.account_id,
      }
    },
    computed: {
      accounts: function () {
        if (this.$store.getters.user) {
          return this.$store.getters.user.accounts
        } else {
          return []
        }
      },
    },
    methods: {
      change_account: function () {
        this.$store.dispatch('set_account_id', this.item)
      },
    },
  }
</script>

<style scoped>

</style>
