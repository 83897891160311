<template>
  <div
    style="cursor: pointer"
    @click="showSetupDialog = true"
  >
    <template v-if="!setupComplete">
      <template v-if="status != null">
        <v-alert
          border="left"
          color="light-blue"
          dark
        >
          Account Setup Status: <strong>{{ status }}</strong>
          <div class="pt-3">
            <v-progress-linear
              color="indigo accent-4"
              indeterminate
              rounded
              height="6"
            />
          </div>
        </v-alert>
      </template>
      <v-dialog
        v-model="showSetupDialog"
        persistent
        width="unset"
      >
        <div class="d-flex justify-center">
          <base-material-card
            color="purple"
            class="px-5 py-3"
          >
            <template v-slot:heading>
              <div class="display-3 font-weight-black d-flex justify-center">
                Account Setup Progress
              </div>

              <div class="subtitle-1 font-weight-bold d-flex justify-center">
                {{ status }}
              </div>
            </template>
            <v-card-text>
              <v-row
                align="center"
                dense
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="googleLinkedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Link your account to Google Analytics
                  </div>
                </v-col>
              </v-row>
              <v-row
                dense
                align="center"
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="websiteSelectedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Website selected from Google Analytics
                  </div>
                </v-col>
              </v-row>
              <v-row
                dense
                align="center"
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="googleDataInitializedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Synchronized data with Google Analytics
                  </div>
                </v-col>
              </v-row>
              <v-row
                dense
                align="center"
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="accountDataInitializedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Account data initialized
                  </div>
                </v-col>
              </v-row>
              <v-row
                dense
                align="center"
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="vendorGenerationEndedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Vendors created
                  </div>
                </v-col>
              </v-row>
              <v-row
                dense
                align="center"
              >
                <v-col cols="1">
                  <v-list-item-action>
                    <v-checkbox
                      v-model="initialReportCreatedAt"
                      color="primary"
                      readonly
                    />
                  </v-list-item-action>
                </v-col>
                <v-col cols="9">
                  <div class="font-weight-light">
                    Initial reports created
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <div class="text-right py-2">
              <v-btn
                elevation="1"
                color="blue darken-1"
                dark
                @click="showSetupDialog = false"
              >
                Close
              </v-btn>
            </div>
          </base-material-card>
        </div>
      </v-dialog>
      <v-dialog
        v-model="showWelcomeMessage"
        persistent
        width="unset"
      >
        <div class="d-flex justify-center">
          <base-material-card
            color="green"
            class="px-5 py-3"
          >
            <template v-slot:heading>
              <div class="display-3 font-weight-black d-flex justify-center">
                Welcome to Dealer Insights!
              </div>
            </template>
            <v-card-text>
              <v-row
                align="center"
                dense
              >
                <v-col>
                  Welcome message goes here
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <div class="text-right py-2">
              <v-btn
                elevation="1"
                color="blue darken-1"
                dark
                @click="showWelcomeMessage = false"
              >
                Close
              </v-btn>
            </div>
          </base-material-card>
        </div>
      </v-dialog>
    </template>
  </div>
</template>

<script>
  import AccountService from '@/services/AccountService.js'
  import { mapGetters } from 'vuex'
  export default {
    name: 'AccountSetup',
    data: () => ({
      showSetupDialog: false,
      error: '',
      status: '',
      googleLinkedAt: null,
      websiteSelectedAt: null,
      googleDataInitializedAt: null,
      accountDataInitializedAt: null,
      vendorGenerationEndedAt: null,
      initialReportCreatedAt: null,
      setupComplete: true,
      showWelcomeMessage: false,
    }),
    computed: {
      ...mapGetters(['accountSetupData']),
    },
    watch: {
      accountSetupData () {
        this.status = this.accountSetupData.status
        this.googleLinkedAt = this.accountSetupData.google_linked_at
        this.websiteSelectedAt = this.accountSetupData.website_selected_at
        this.googleDataInitializedAt = this.accountSetupData.google_data_initialized_at
        this.accountDataInitializedAt = this.accountSetupData.account_data_initialized_at
        this.vendorGenerationEndedAt = this.accountSetupData.vendor_generation_ended_at
        this.initialReportCreatedAt = this.accountSetupData.initial_report_created_at
        this.setupComplete = !(this.accountSetupData.setup_completed_at === null)
        this.showWelcomeMessage = this.googleLinkedAt === null && this.websiteSelectedAt === null && this.googleDataInitializedAt === null && this.accountDataInitializedAt === null && this.vendorGenerationEndedAt === null
      },
    },
    created () {
      this.getAccountSetup()
    },
    methods: {
      getAccountSetup () {
        this.error = ''
        AccountService.getAccountSetup()
          .then(response => {
            if (response.data && response.data.length > 0) {
              this.status = response.data[0].status
              this.googleLinkedAt = response.data[0].google_linked_at
              this.websiteSelectedAt = response.data[0].website_selected_at
              this.googleDataInitializedAt = response.data[0].google_data_initialized_at
              this.accountDataInitializedAt = response.data[0].account_data_initialized_at
              this.vendorGenerationEndedAt = response.data[0].vendor_generation_ended_at
              this.initialReportCreatedAt = response.data[0].initial_report_created_at
              this.setupComplete = !(response.data[0].setup_completed_at === null)
              this.showWelcomeMessage = this.googleLinkedAt === null && this.websiteSelectedAt === null && this.googleDataInitializedAt === null && this.accountDataInitializedAt === null && this.vendorGenerationEndedAt === null
            }
          })
          .catch(error => {
            this.error = error.response.status.toString()
          })
      },
    },
  }
</script>
