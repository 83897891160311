<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />
    <!-- <div class="px-5 py-3">
      <account-setup />
    </div> -->
    <div class="px-5 py-3">
      <release />
    </div>
    <v-spacer />
    <div class="mx-3" />
    <account-select />
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template
        v-if="notifications.length > 0"
        v-slot:activator="{ attrs, on }"
      >
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template
              v-slot:badge
            >
              <span>{{ notifications.length }} </span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            :to="p.link"
          >
            <v-list-item-title v-text="p.title" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import AccountSelect from '@/components/AccountSelect.vue'
  import AccountSetup from '@/components/AccountSetup.vue'
  import Release from '@/components/Release.vue'

  // Utilities
  import { mapState, mapMutations, mapGetters } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AccountSetup,
      Release,
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
      AccountSelect,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      notifications: [
      ],
      profile: [],
    }),
    computed: {
      ...mapState(['drawer']),
      ...mapGetters(['appName', 'permissions', 'user']),
    },
    watch: {
      appName () {
        this.generateMenus()
      },
    },
    created () {
      this.generateMenus()
    },
    methods: {
      logout () {
        this.$store.dispatch('logout')
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      generateMenus () {
        if (this.appName === 'contact') {
          var menus = []

          menus.push({ title: 'Profile', link: '/attempting_contact/profile/' })

          // if (this.permissions.includes('di_admin') || this.user.group_admin) {
          menus.push({ title: 'Settings', link: '/settings' })
          // }
          menus.push({ title: 'DMS Import Batches', link: '/attempting_contact/dms_import_batches' })
          if (this.permissions.includes('account_admin') || this.permissions.includes('di_admin')) {
            menus.push({ title: 'Manage Users', link: '/attempting_contact/manage_users' })
          }
          menus.push({ divider: true })
          menus.push({ title: 'Log out', link: '/login' })
          this.profile = menus
        }
        if (this.appName === 'insights') {
          this.profile = [
            { title: 'Settings', link: '/settings' },  
            { title: 'DMS Import Batches', link: '/dms_import_batches' },             
            { divider: true },
            { title: 'Log out', link: '/login' },
          ]
        }
      },
    },
  }
</script>
