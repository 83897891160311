<template>
  <div>
    <v-dialog
      v-model="showReleaseDialog"
      persistent
      width="400"
    >
      <v-alert
        prominent
        type="warning"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong>A new version of the app is available!</strong>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col class="grow">
            <p>Please refresh your browser.</p>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Release',
    data: () => ({
      showReleaseDialog: false,
    }),
    computed: {
      ...mapGetters(['release']),
    },
    watch: {
      release () {
        this.showReleaseDialog = true
      },
    },
    created () {
    },
    methods: {
    },
  }
</script>
